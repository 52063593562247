<script>
import hamMenu from "@/assets/Images/hamMenu.svg";
import returnHome from "@/assets/Images/returnHome.svg";
import searchIcon from "@/assets/Images/searchIcon.svg";
import newIcon from "@/assets/Images/plusWhite.svg";
import addCounter from "@/assets/Images/addCounter.svg";
import minusCounter from "@/assets/Images/minusCounter.svg";
import dustBinIcon from "@/assets/Images/dustbin.svg";
import penIcon from "@/assets/Images/editPen.svg";
import CustomHeader from "@/components/CustomHeader.vue";
import ChangePrice from "@/components/Order/ChangePrice.vue";
import OrderItemNote from "@/components/Order/OrderItemNote.vue";
import ProductCount from "@/components/Order/ProductCount.vue";
import duplicate from "@/assets/Images/duplicate.svg";

import { mapGetters } from "vuex";
import * as mutationTypes from "@/store/mutationTypes";
import DeleteConfirmationPopUp from "@/components/DeleteConfirmationPopUp.vue";

export default {
  name: "OrderCart",
  components: {
    ChangePrice,
    CustomHeader,
    DeleteConfirmationPopUp,
    OrderItemNote,
    ProductCount,
  },
  data() {
    return {
      addCounter,
      baseUrl: "",
      cart: [],
      count: 0,
      changePrice: false,
      deleteIndex: 0,
      drpdown: null,
      duplicate,
      dustBinIcon,
      noteView: false,
      hamMenu,
      item_count: 1,
      newIcon,
      minusCounter,
      penIcon,
      preShippingTotal: 0,
      price_to_change: null,
      product_name_to_change: "",
      returnHome,
      searchIcon,
      visible: false,
      visiblePopUp: false,
      showNote: false,
      note: "",
      showOverlay: false,
      overlayTitle: "",
      overlayMessage: "",
      overlaySubMessage: "",
      showPopUp: false,
    };
  },
  methods: {
    checkPriceAndQty(i) {
      // to ensure enough qty is available for sale
      // perhaps set this to be triggered by watching the plus property
      // because it sets product.count to 1 everytime it runs
      let product = this.cart[i];
      product.count = 1;
      product.qty_of_selected = 0;

      if (product.variants.length === 1) {
        const index = product.options1.indexOf(product.selected_variant1)

        product.qty_of_selected = product.combinationsData[index][0]
        product.price_of_selected = product.combinationsData[index][1]

        product.selected_position = index
      }
      if (product.variants.length === 2) {
        for (let i=0; i < product.cartProd.length; i++) {
          if (
            product.cartProd[i][0] == product.selected_variant1 &&
            product.cartProd[i][1] == product.selected_variant2
          ) {
            product.qty_of_selected = product.combinationsData[i][0]
            product.price_of_selected = product.combinationsData[i][1]

            product.selected_position = i
          }
        }
      }
      this.applyDiscount(product);
    },
    checkVariantsSelected(product, referrer) {
      // checks to see if variants have been selected
      if (product.variants.length === 2) {
        if (
          product.selected_variant1 !== "" &&
          product.selected_variant2 !== ""
        ) {
          return true;
        } else if (product.selected_variant1 != "") {
          referrer == "proceed"
            ? // an alert was here before
              (this.showPopUp = true)
            : alert(
                "An option for " + product.variants[1] + " is required"
              );
        } else {
          referrer == "proceed"
            ? (this.showPopUp = true)
            : alert(
                "An option for " + product.variants[0] + " is required"
              );
        }
      } else if (product.variants.length === 1) {
        // one variant
        if (product.selected_variant1 != "") {
          return true;
        } else {
          referrer == "proceed"
            ? (this.showPopUp = true)
            : alert(
                "An option for" + product.variants[0] + " is required."
              );
        }
      } else {
        return true;
      }
    },
    computeSubtotal(i) {
      let product = this.cart[i];
      product.has_variant
        ? (product.subTotal = product.price_of_selected * product.count)
        : (product.subTotal = product.price * product.count);
    },
    deleteFromCart(i) {
      this.cartMap.splice(i, 1);
      this.cart.splice(i, 1);
    },
    editPrice(i) {
      let product = this.cart[i];
      if (this.checkVariantsSelected(product)) {
        this.changePrice = true;
        this.price_to_change = i;
        this.product_name_to_change = product.product_name;
      }
    },
    getItemById(i) {
      return this.$parent.ImgCard.find((item) => item.id === i);
    },
    minus(i) {
      let product = this.cart[i];
      if (product.count > 1) {
        product.count > 0 ? product.count-- : "";
      } else {
        alert("Use the delete button to remove this item from your cart");
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    plus(i) {
      let product = this.cart[i];

      if (product.has_variant) {
        if (this.checkVariantsSelected(product)) {
          if (product.strict_stock_count) {
            product.qty_of_selected > product.count
              ? product.count++
              : alert("All avaialable products are in your cart");
          } else {
            product.count++;
            this.item_count = product.count;
          }
        }
      } else {
        if (product.strict_stock_count) {
          product.total_stock > product.count
            ? product.count++
            : alert("All avaialable products are in your cart");
        } else {
          product.count++;
          this.item_count = product.count;
        }
      }
    },
    setOldPrice(product) {
      product.has_variant
        ? (product.old_price = product.price_of_selected)
        : (product.old_price = product.price);
    },
    selectCustomer() {
      // ensures variant options are selected where applicable
      // before proceeding to select customer
      let pass = null;
      for (let i = 0; i < this.cart.length; i++) {
        let product = this.cart[i];
        if (!this.checkVariantsSelected(product, "proceed")) {
          pass = false;
          break;
        } else {
          pass = true;
        }
      }

      pass ? this.$router.push("/choose_customer/:origin") : "";
    },
    selectOption(variant, ref, i) {
      variant == "one"
        ? (this.cart[i].selected_variant1 = this.$refs[ref][0].value)
        : (this.cart[i].selected_variant2 = this.$refs[ref][0].value);
      this.checkPriceAndQty(i);
      this.computeSubtotal(i);
    },
    updateNote(i, note) {
      let product = this.cart[i];
      product.note = note;
    },
    updatePrice(i, amount) {
      let product = this.cart[i];
      product.sale = true; // turn off sale if discount removed

      // if (product.has_variant) {
      //   // don't change sequence of following two lines
      //   // this is done to maintain product.price as main price

      //   product.price_of_selected = amount;
      // } else {
      //   // don't change sequence of following two lines
      //   // this is done to maintain product.price as main price
      //   product.old_price = product.price; // DON'T CHANGE SEQUENCE
      //   product.price = amount; // DONT'T CHANGE SEQUENCE
      // }

      product.has_variant
        ? (product.price_of_selected = amount)
        : (product.price = amount);

      this.computeSubtotal(i);
    },
    applyDiscount(obj) {
      this.setOldPrice(obj)
      if (obj.has_variant) {
        if (obj.discount_type === '1') { // percent
          obj.price_of_selected = obj.price_of_selected - (obj.price_of_selected * obj.discount / 100).toFixed(0)
        } else {
          obj.price_of_selected = obj.price_of_selected - obj.discount
        } 
      } else {
        if (obj.discount_type === '1') {
          obj.price = obj.price - (obj.price * obj.discount / 100).toFixed(0)
        } else {
          obj.price = obj.price - obj.discount
        }
      }
      obj.sale = true
      return obj
    },
    handleConfirm() {
      this.showNote = false;
      this.showOverlay = false;
    },
    handleCancel() {
      this.showNote = false;
      this.showOverlay = false;
    },
    handleOverlay(i) {
      let product = this.cart[i];
      this.showOverlay = true;
      this.overlayTitle = "Delete from cart";
      this.overlayMessage =
        "Do you want to remove " + product.product_name + " from your cart?";
      this.overlaySubMessage = "";
      this.deleteIndex = i;
    },
    displayNote(i) {
      let ref = "note" + i;
      this.$refs[ref][0].style.display = "block";
      // display note textarea

      // this.inventory[index].showNote = true;
      // this.drpdown = null;
    },
    toggleMoreOptions(index) {
      this.drpdown = this.drpdown === index ? null : index;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.drpdown = null;
      }
    },
    updateProductCount(count, i) {
      let product = this.cart[i];
      product.count = count;
      this.computeSubtotal(i);
    },
  },
  computed: {
    ...mapGetters({
      cartMap: "getCartMap",
      inventory: "getInventory",
      cartItems: "getUnpackedCart",
    }),
  },
  watch: {
    cart: {
      handler() {
        let cart = {};
        let total = 0;
        let cart_count = 0;
        for (let i = 0; i < this.cart.length; i++) {
          total += this.cart[i].subTotal;
          cart_count += this.cart[i].count;
        }
        // this.preShippingTotal = this.cart.reduce((total, item) => {
        //     return Number(total + item.subTotal);
        //   }, 0)
        cart.items = this.cart;
        cart.preShippingTotal = total;
        this.preShippingTotal = this.numberWithCommas(total);
        cart.unique_in_cart = this.cart.length;
        cart.total_items = cart_count;
        this.$store.commit(mutationTypes.SAVE_CHECKOUT_CART, cart);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.cartMap.length > 0) {
      let till = [];
      let inventory = this.inventory;

      this.cartMap.map((item_id) => {
        let obj = inventory.find((item) => item.id == item_id);

        let new_obj = {};
        Object.assign(new_obj, obj);

        if (new_obj.has_variant) {
          new_obj.count = 0

          if (new_obj.variants.length > 0) {
            const cartesianProduct = (A, B) => {
              return A.flatMap(a => B.map(b => [a, b]));
            };

            new_obj.cartProd = cartesianProduct(new_obj.options1, new_obj.options2);
          }
        } else {
          new_obj.count > 0 ? "" : (new_obj.count = 1); // in case cart is recovered from localStorage & has count. Ensure object not created twice
          new_obj.has_discount ? this.applyDiscount(new_obj) : ''
        }
        new_obj.selected_variant1 = "";
        new_obj.selected_variant2 = "";
        new_obj.subTotal = new_obj.price;
        till.push(new_obj);
      });
      this.$store.commit(mutationTypes.SAVE_UNPACKED_CART, till);
      this.cart = till;
    }
    this.baseUrl = window.location.origin;
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true);
  },
};
</script>
<template>
  <CustomHeader text="Product selection" />
  <div style="height: 30px;">
    <!-- pushes CustomHeader down -->
  </div>
  <div class="card_container" @click="handleClickOutside">
    <div class="semi-header">
      <p>{{ cartMap.length }} items in cart</p>
    </div>
    <div class="card_holder">
      <div class="item_card" v-for="(item, index) in cart" :key="index">
        <div class="card_top">
          <div class="card_top_details">
            <div class="img_wrapper">
              <div v-if="item.images.length > 0">
                <img
                  v-if="baseUrl == 'http://127.0.0.1:8080'"
                  :src="'http://127.0.0.1:8000' + item.images[0].image"
                  alt="img"
                />
                <img v-else :src="item.images[0].image" alt="" class="cart_img" />
              </div>

              <div v-else>
                <img src="@/assets/Images/default_product_image.png" alt="img" />
              </div>
            </div>
            <div class="ct_info">
              <h1>{{ item.product_name }}</h1>
              <div class="ct_info_dets">
                <p>
                  &#8358;
                  <span class="price_area" v-if="item.has_variant">
                    {{ item.price_of_selected }}
                  </span>
                  <span class="price_area" v-else> {{ item.price }}</span>

                  <b v-if="item.sale">
                    Old price
                    <span class="price_area">{{ item.old_price }} </span>
                  </b>
                </p>
                <p v-if="item.subTotal != 0 && item.subTotal !== undefined">
                  Sub total: &#8358; {{ item.subTotal }}
                </p>
              </div>
            </div>
            <img
              :src="hamMenu"
              alt="ham menu icon"
              @click.stop="toggleMoreOptions(index)"
              ref="hamMenu"
            />
          </div>
          <div v-if="drpdown === index" class="options" ref="options">
            <p @click="editPrice(index)" class="list">
              <img :src="penIcon" alt="penIcon" class="pen" /> Update sales
              price
            </p>
              <p class="list" >
              <img :src="duplicate" alt="note" class="" /> Duplicate item
            </p>
            <p class="list" @click="displayNote(index)">
              <img :src="duplicate" alt="note" class="" /> Add notes
            </p>
            
          </div>
        </div>

        <div>
          <div class="optional" v-if="item.has_variant">
            <div class="clr">
              <label for="">{{ item.variants[0] }}</label>
              <select
                :ref="'var1_' + index"
                @change="selectOption('one', 'var1_' + index, index)"
              >
                <option selected disabled>
                  Select {{ item.variants[0] }}
                </option>
                <option
                  v-for="(option, i) in item.options1"
                  :value="option"
                  :key="i"
                >
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="siz clr" v-if="item.variants.length > 1">
              <label for="">{{ item.variants[0] }}</label>
              <select
                :ref="'var2_' + index"
                @change="selectOption('two', 'var2_' + index, index)"
              >
                <option selected disabled>
                  Select {{ item.variants[1] }}
                </option>
                <option
                  v-for="(option, i) in item.options2"
                  :value="option"
                  :key="i"
                >
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="card_buttom">
            <div @click="handleOverlay(index)" class="list">
              <img :src="dustBinIcon" alt="thrash Icon" class="bin" />
            </div>
            <div class="counter">
              <img
                :src="minusCounter"
                alt=""
                @click="minus(index)"
                :disabled="count === 0"
              />
              <ProductCount
                :index="index"
                :newCount="item.count"
                @updateCount="updateProductCount"
              />
              <img :src="addCounter" alt="" @click="plus(index)" />
            </div>
          </div>
          <div :ref="'note' + index" class="hide">
            <OrderItemNote :index="index" @updateNote="updateNote" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <PopUpScreen
    :isVisible="showPopUp"
    @close="showPopUp = false"
    title="Variants needed"
    header="Looks like something is missing"
    message="Ensure options have been selected for all items in cart"
  />
  <ChangePrice
    :visible="this.changePrice"
    :index="price_to_change"
    :product_name="product_name_to_change"
    @close="this.changePrice = false"
    @newPrice="this.updatePrice"
  />
  <div class="cart_total">
    <div>
      <p>Total ({{ this.cart.length }} items)</p>
      <p>&#8358; {{ preShippingTotal }}</p>
    </div>
    <button @click="selectCustomer()">Link customer</button>
  </div>
  <div style="display: block; height: 120px"><!--props page up--></div>
  <DeleteConfirmationPopUp
    :visiblePopUp="showOverlay"
    :title="overlayTitle"
    :message="overlayMessage"
    :sub_message="overlaySubMessage"
    :index="deleteIndex"
    @confirm="handleConfirm"
    @cancel="handleCancel"
    @delete="deleteFromCart"
  />
</template>

<style scoped>
.cart_total {
  width: 90%;
  padding: 20px;
  background: #f5fcf4;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #c4dbd5;
  position: fixed;
  bottom: 0;
}
.cart_total div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cart_total button {
  width: 100%;
  padding: 10px;
}
.cart_total p {
  padding: 0 0 10px;
}
.img_wrapper {
  margin-right: 12px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img_wrapper img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  border-radius: 8px;
  aspect-ratio: 1 / 1;
}
button {
  background: #008060;
  border: none;
  border-radius: 4px;
}
button > a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

button > a > img {
  margin: 0 4px;
}

.btn {
  margin-bottom: 15px;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn > button {
  width: 95%;
  padding: 12.5px 0;
}

.next {
  color: #002e22;
}

.arr {
  padding: 4px;
  background-color: rgba(53, 95, 83, 0.5);
  border-radius: 4px;
  margin: 0 8px;
}

.semi-header {
  display: flex;
  margin: 24px 0px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sorting-options {
  display: flex;
}

.card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
  margin: 0 16px;
}

.price_area {
  display: flex;
  padding: 6px;
  background: white;
  width: 25%;
  border: 1px solid #c4dbd5;
  border-radius: 4px;
  margin: 0 4px;
}

button {
  color: white;
  font-size: 14px;

  background: #008060;
  border-radius: 4px;
  border: none;
}

a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
}
.optional {
  display: flex;
  justify-content: space-between;
}
.clr {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.clr > label {
  margin: 8px 0;
  color: #355f53;
}
.pen {
  width: 20px;
}
select {
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  outline: none;
  appearance: none;
  background-image: url("@/assets/Images/select.svg");
  background-size: 25px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-right: 25px;
  background-color: #f5fcf4;
  font-family: "Aeonik";
  color: #92aaa3;
}

.card_holder {
  width: 100%;
}
.item_card {
  margin-bottom: 8px;
  padding: 16px 12px;
  background: #f5fcf4;
  display: flex;
  flex-direction: column;
  border: #c4dbd5 1px solid;
  border-radius: 8px;
}

.card_top {
  border-bottom: #c4dbd5 1px solid;
  width: 100%;
}

.card_top_details {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.card_top_details > img {
  margin-right: 12px;
}
.ct_info {
  width: 70%;
}
.ct_info > h1 {
  font-size: 16px;
  color: #355f53;
  font-weight: 500;
  margin-bottom: 6px;
}

.ct_info_dets > p {
  display: flex;
  align-items: center;
  padding: 4px 0;
  font-size: 14px;
  color: #002e22;
  font-weight: 500;
}

.card_buttom {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
  border-top: #c4dbd5 1px solid;
  padding-top: 16px;
  align-items: center;
}

.card_buttom > p {
  margin: 0 12px;
  width: 40px;
  height: 40px;
  color: #355f53;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #c4dbd5 1px solid;
}
.counter {
  display: flex;
}
.cart_img {
  width: 52px;
  height: 52px;
  margin-right: 12px;
  border-radius: 4.25px;
  width: 100%;
  max-width: 120px;
  border-radius: 8px;
  /* border: #c4dbd5 1px solid; */
  aspect-ratio: 1 / 1;
}
.bin {
  width: 16px;
  height: 16px;
}
.options {
  position: absolute;
  border-radius: 8px;
  right: 0;
  padding: 12px;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: -60px 10px;
  box-shadow: -2px 4px 16px 0px #d1e4de80;
}

.options .list {
  padding: 10px 4px;
}
.options .list > img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.thrash {
  color: red;
}
.hide {
  display: none;
}
</style>
