<script>
export default {
  name: "CarouselSlide",
  props: ["slides"],
  data() {
    return {
      active: 0,
      slideIndex: 1,
      baseUrl: '',
    };
  },
  methods: {
    plusSlides(n) {
      this.showSlides(this.slideIndex += n);
    },
    currentSlide(n) {
      this.showSlides(this.slideIndex = n);
    },
    showSlides(n) {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("dot");

      if (n > slides.length) {this.slideIndex = 1}    
      if (n < 1) {this.slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
      }

      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex-1].style.display = "block";
      this.slides.length > 1
        ? dots[this.slideIndex-1].className += " active"
        : ''
    },
  },
  mounted() {
    this.showSlides(this.slideIndex)
    this.baseUrl = window.location.origin
	},  
};
</script>
<template>
  <div>
    <div class="slideshow-container">
      <div v-for="image, i in slides" :key="i" class="mySlides fade">
        <div class="numbertext">{{ i+1 }} / {{ slides.length }}</div>
        <div class="image-preview">
          <img v-if="baseUrl == 'http://127.0.0.1:8080'"
            :src="'http://127.0.0.1:8000' + image.image"
            alt="product image"
          />
          <img v-else :src="image.image" alt="product image" />
        </div>
      </div>

      <span v-if="slides.length > 1">
        <p class="prev" @click="plusSlides(-1)">❮</p>
        <p class="next" @click="plusSlides(1)">❯</p>
      </span>
    </div>
    <br>

    <div style="text-align:center">
      <div class="fade">
        <div v-for="image, i in slides" :key="i">
          <div class="text" v-if="slideIndex == i">
            {{ image.name }}
          </div>
        </div>
    </div>
    <span v-if="slides.length > 1">
      <span v-for="image, i in slides" :key="i" class="dot" @click="currentSlide(i+1)"></span>
    </span>
    </div>
  </div>
</template>	

<style scoped>
* {box-sizing: border-box}
  body {font-family: Verdana, sans-serif; margin:0}
  .mySlides {display: none}
  img {vertical-align: middle;
}
.image-preview {
  aspect-ratio: 1/1;
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  overflow: hidden;
}
.image-preview img {
  width: 100%
}
/* Slideshow container */
.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
  border-radius: 12px;
  overflow: hidden;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #008060;
  font-weight: bold;
  font-size: 48px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #008060;
  font-size: 20px;
  margin: -5px 0 10px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #008060;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #DAF1DD;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #008060;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next, .text {font-size: 11px}
}
</style>
