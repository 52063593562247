<script>
export default {
  name: "OrderItems",
  props: ["item"],
  data() {
    return {
      baseUrl: "",
    };
  },
  methods: {
  },
  mounted() {
    this.baseUrl = window.location.origin;
  },
};
</script>

<template>
  <div  class="orderItemsContainer" >
    <div class="orderItemsImg">
      <div v-if="item.images.length > 0">
        <img
          v-if="baseUrl == 'http://127.0.0.1:8080'"
          :src="'http://127.0.0.1:8000' + item.images[0].image"
          alt="img"
        />
        <img v-else :src="item.images[0].image" alt="img" />
      </div>
      <div v-else>
        <img src="@/assets/Images/default_product_image.png" alt="img" />
      </div>
    </div>

  <div class="orderItemsDets">
    <div >
      <p class="p">{{ item.product_name }}</p>
      <p class="btm_text">Qty: {{ item.qty }}</p>
      <p>
        <span v-if="item.selected_option1 != ''">{{ item.selected_option1 }}</span> &nbsp;
        <span v-if="item.selected_option2 != ''">{{ item.selected_option2 }}</span>
      </p>
      <p v-if="item.note!=''">
        Note: {{ item.note }}
      </p>
    </div>

    <div class="product-price">
      <p class="p">&#8358;{{ Number(item.sub_total).toLocaleString() }}</p>
      <span v-if="item.status == 0" class="active btm_text">Outstanding</span>
      <span v-else class="active btm_text">Delivered</span>
    </div>
  </div>
  </div>
</template>
<style scoped>
.orderItemsContainer{
  display: flex;
  padding: 8px;
  align-items: center;

}
.orderItemsImg{
  width: 20%;
 border-radius: 6px;
 outline: #c4dbd5 1px solid;
}
.orderItemsImg img{
  width:48px;
  height:48px;
object-fit:contain;
}
.orderItemsDets{
  padding: 0px;
  padding-left: 8px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  font-size: 14px;
}
.p{
  font-size: 14px;
  font-weight:500;
  margin-bottom:5px ;
  color: #143E32;
}
.btm_text{
  color: #008060;
}
</style>
