<script>
import { mapGetters } from "vuex";
import {
  createPaystackCustomer,
  fetchOrderItems,
  paystackInvoiceRequest,
  updateCustomer,
  updateOrder,
  deleteOrder,
} from "@/services/apiServices";
import { UPDATE_SALE, DELETE_ORDER } from "@/store/mutationTypes"
import { jsPDF } from 'jspdf'
import MenuOptionsLarge from "@/components/Order/MenuOptionsLarge";

import * as mutationTypes from "@/store/mutationTypes";

import dustBinIcon from "@/assets/Images/dustbin.svg";
import hamMenu from "@/assets/Images/hamMenu.svg";
import truckIcon from "@/assets/Images/grayTruck.svg";
import cancelStroke from "@/assets/Images/cancelStroke.svg";
import chainLink from "@/assets/Images/chainLink.svg";
import cancelRed from "@/assets/Images/cancelRed.svg";
import copyIcon from "@/assets/Images/copyIcon.svg";
import sheet from "@/assets/Images/sheet.svg";
import sourceIcon from "@/assets/Images/sourceIcon.svg";
import DeleteConfirmationPopUp from "@/components/DeleteConfirmationPopUp.vue";
import PopUpScreen from "@/components/PopUpScreen.vue";
import penIcon from "@/assets/Images/penGray.svg";
import moreIcon from "@/assets/Images/more.svg";
import grayCalendar from "@/assets/Images/grayCalendar.svg";
import UpdateScreen from "@/components/Order/UpdateScreen";
import AddPayment from "@/components/Order/AddPayment.vue";
import threeDots from "@/assets/Images/threeDotsGray.svg";
import recycle from "@/assets/Images/recycle.svg";
import share from "@/assets/Images/share.svg";
import grayCancel from "@/assets/Images/grayCancel.svg";
import ShareInvoice from "@/components/Order/ShareInvoice.vue";
import OrderIssue from "@/components/Order/OrderIssue.vue";
// import AddIssue from "@components/Order/AddIssue.vue";
// import IssueChat from "@components/Order/IssueChat.vue";
// import SetStatus from "@components/Order/SetStatus";
// import ReturnOrder from "@components/Order/ReturnOrder.vue";
// import ConfirmReturn from "@components/Order/ConfirmReturn.vue";
// import ReturnApproval from "@components/Order/ReturnApproval.vue";
// import ReturnedItems from "@components/Order/ReturnedItems.vue";
export default {
  name: "OrderMenu",
  props: {
    order: []
  },
  data() {
    return {
      // visible: false,


      hamMenu,
      truckIcon,
      penIcon,
      moreIcon,
      cancelStroke,
      dustBinIcon,
      chainLink,
      cancelRed,
      sheet,
      share,
      copyIcon,
      grayCalendar,
      threeDots,
      recycle,
      grayCancel,
      deleteIndex: 0, // probably not used here
      sourceIcon,
      visiblePopUp: false,
      showOverlay: false,
      activeOrder: {},
      order_items: [],
      showItems: false,
      overlayTitle: "",
      overlayMessage: "",
      overlaySubMessage: "",
      placeholderText: "",
      textBox: false,
      isPopUpVisible: false,
      btnText: "",
      message: "",
      headerText: "Date",
      approvalTitle: "",
      approvalMessage: "",
      approvalHeader: "",
      editOrderDate: false,
      approvalContent: false,
      btnOption: false,
      visibleModal: false,
      updatePaymentPopUp: false,
      payment_status: null,
      no_payment: false,
      moreList: false,
      shareInvoiceModal: false,
      issueModal: false,
      issueForm: false,
      chatModal: false,
      setStatusPopUp: false,
      returnOrderModal: false,
      confirmReturnModal: false,
      returnApprovalModal: false,
      returnItemsModal: false,
      
      largeMenuOption: null,
    };
  },
  components: {
    OrderIssue,
    DeleteConfirmationPopUp,
    PopUpScreen,
    UpdateScreen,
    AddPayment,
    ShareInvoice,
    // AddIssue,
    // IssueChat,
    // SetStatus,
    // ReturnOrder,
    // ConfirmReturn,
    // ReturnApproval,
    // ReturnedItems,

    MenuOptionsLarge,
  },
  methods: {
    closeMenu() {
      this.$emit("closeMenu")
    },
    toggleMoreOptions() {
      // this.visible = !this.visible;
      if (this.visible) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    getCustomerPSCode() {
      if (this.customer.paystack_code !== undefined) {
        return this.customer.paystack_code;
      } else {
        if (typeof this.customer.email == "undefined") {
          alert("Customer requires an email address to create an invoice.");
          return null;
        }

        let data = {
          email: this.customer.email,
        };

        createPaystackCustomer(data).then((res) => {
          let data = {
            paystack_code: res.data.data.customer_code,
            paystack_id: res.data.data.id,
          };

          updateCustomer(data, this.customer.id).then((res) => {
            this.$store.commit(mutationTypes.UPDATE_CUSTOMER, {
              id: this.customer.id,
              updated_customer: res.data,
            });

            return res.data.paystack_code;
          });
        });
      }
    },
    handleClickOutside(event) {
      if (
        this.$refs.options &&
        this.$refs.hamMenu &&
        !this.$refs.options.contains(event.target) &&
        !this.$refs.hamMenu.contains(event.target)
      ) {
        // this.visible = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleConfirm() {
      this.delete();
      this.showOverlay = false;
    },
    handleCancel() {
      this.showOverlay = false;
      this.isPopUpVisible = false;
      this.visibleModal = false;
      this.issueModal = false;

      this.largeMenuOption = null
    },
    handlePopUpCancel() {
      this.updatePaymentPopUp = false;
      this.shareInvoiceModal = false;
      this.issueForm = false;
      this.chatModal = false;
      this.returnOrderModal = false;
      this.confirmReturnModal = false;
      this.returnItemsModal = false;
      this.returnApprovalModal = false;
    },
    handleStatusCancel() {
      this.setStatusPopUp = false;
    },
    handleOverlay() {
      this.showOverlay = true;
      this.overlayMessage = "order";
    },
    removeOrder() {
      deleteOrder(this.activeOrder.id);
      this.$store.commit(DELETE_ORDER, this.activeOrder.id);
      alert("order has been deleted");
      this.showOverlay = false;
    },
    editOrder(order, action) {
      switch (action) {
        case 0: // mark complete
          // this.visible = false;
          {
            let data = { fulfilled: 1 };
            updateOrder(data, order.id) // assuming you can only mark fulfilled
              .then((res) => {
                this.$store.commit(UPDATE_SALE, res.data);
                // also update order items
                // give warning that it's not reversible - unless returned
                // except an admin - roles
                alert("Order updated to complete");
              });
            break;
          }
        case 1: // delete order
          this.activeOrder = order;
          this.showOverlay = true;
          this.overlayTitle = "Delete order";
          this.overlayMessage =
            "This will permanently delete this order and all associated data";
          this.overlaySubMessage = "";
          break;
        // case 2: // update paid amount
        //   null
        //   break;
        // case 3: // cancel
        //   null
        //   break;
        // case 4:
        //   null
        //   break;
        // default:
        //   null
      }
    },
    editDate() {
      this.editOrderDate = true;
      this.overlayTitle = "Edit order date";
      this.isPopUpVisible = true;
      // this.showOverlay = true
      this.textBox = true;
      this.placeholderText = "Mar 22, 2024";
      this.btnText = "Send for approval";
      this.headerText = "Date";
      this.btnOption = true;
    },
    updatePayment() {
      this.visibleModal = true;
      this.payment_status = true;
    },
    addPaymentPop() {
      this.updatePaymentPopUp = true;
    },
    markComplete() {
      this.largeMenuOption = "markComplete"
    },
    sendApprovalRequest() {
      (this.editOrderDate = false),
        (this.approvalContent = true),
        (this.isPopUpVisible = true);
      (this.approvalTitle = "Date change request"),
        (this.approvalMessage =
          "Do you want to allow change of the for the order below"),
        (this.approvalHeader = "Approve change of date");
      this.btnOption = false;
    },
    moreGroup() {
      this.moreList = true;
    },
    nextPage() {
      (this.returnOrderModal = false), (this.confirmReturnModal = true);
    },
    previousPage() {
      (this.returnOrderModal = true), (this.confirmReturnModal = false);
    },
    approval() {
      this.returnApprovalModal = true;
    this.confirmReturnModal = false;
    },
    mainPage(){
      this.returnOrderModal = false;
      this.returnItemsModal = true;
    },
    subPage() {
      this.returnOrderModal = true;
      this.returnItemsModal = false;
    },
     goBack() {
       this.confirmReturnModal = true;
       this.returnApprovalModal = false;
    },
    showLess() {
      this.moreList = false;
    },
    showChatBox() {
      this.chatModal = true;
    },
    showOrderReturnModal() {
      this.returnOrderModal = true;
    },
    showIssue() {
      this.issueModal = true;
    },
    showIssueForm() {
      this.issueForm = true;
    },
    showSetStatusPopUp() {
      this.setStatusPopUp = true;
    },
    displayReceipt(type) {
      if (type == 'receipt') {
        const doc = new jsPDF()
        const receipt = document.getElementById('receipt')
        doc.html(receipt, {
          callback: function(doc) {
            doc.save('receipt.pdf')
          },
          x: 15,
          y: 15,
          width: 170, // target width in PDF document
          windowWidth: 650 // window width in CSS pixels
        })
      } else {
        // generates paystack payment link //
        // shareInvoiceLink() {
        this.toggleMoreOptions();
        let PSCode = this.getCustomerPSCode();

        if (PSCode) {
          var line_items = [];
          // if (this.order_items.length < 1) {
          fetchOrderItems(this.order.order_ref).then((res) => {
            this.order_items = res.data;

            // was trying to do everything below outside the fetchOrderItems' .then
            // but it seemed they ran assynchronsously
            for (let i = 0; i < this.order_items.length; i++) {
              let item = {
                name: this.order_items[i].product_name,
                amount: (this.order_items[i].price_sold * 100).toString(),
                quantity: this.order_items[i].qty,
              };
              line_items.push(item);
            }

            line_items.push({
              name: "Delivery fee",
              amount: (this.order.shipping * 100).toString(),
              quantity: 1,
            });

            let data = {
              description: "Invoice for order " + this.order.order_ref,
              line_items: line_items,
              customer: PSCode,
              due_date: "2020-07-08",
            };

            paystackInvoiceRequest(data)
              .then((res) => {
                alert("Invoice for order x has been sent to user");
                console.log(res)
                // mark as invoice sent
              })
              .catch((err) => {
                console.log("error", err.response.data);
              });
          });
          // }
        }
        this.shareInvoiceModal = true;
      }
      this.$emit("closeMenu")
    },
  },
  computed: {
    ...mapGetters({
      customers: "getCustomers",
    }),
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<template>
  <div class="options" ref="options" style="border: 0.5px solid green;">
    <!-- <p to="/changePrice" class="list" @click="editDate()">
      <img :src="penIcon" alt="cancelStroke" />Edit date
    </p>
    <p to="/changePrice" class="list" @click="updatePayment()">
      <img :src="grayCalendar" alt="cancelStroke" />Update payment
    </p>
    <p class="list" @click="editOrder(order, 1)">
      <img :src="dustBinIcon" alt="dustbin" />Delete entry
    </p> -->
    <p class="list" @click="markComplete()">
      <img :src="truckIcon" alt="truck icon" />Mark complete
    </p>
    <!-- <p class="list" @click="showIssue()">
      <img :src="cancelStroke" alt="issue" />Raise an issue
    </p>
    <p class="list" @click="showOrderReturnModal()">
      <img :src="recycle" alt="cancelStroke" />Return item
    </p> -->
    <p v-if="order.payment_status == 1" @click="displayReceipt('receipt')" class="list">
      <img :src="share" alt="cancel Icon" />Download receipt
    </p>
    <p v-else @click="displayReceipt('invoice')" class="list">
      <img :src="share" alt="cancel Icon" />Download invoice
    </p>
  </div>

    <div>
      <Transition :duration="{ enter: 500, leave: 800 }" name="nested">
        <MenuOptionsLarge
          :option="largeMenuOption"
          :order="order"
          @cancel="handleCancel"
          @closeMenu="closeMenu"
        />
      </Transition>


      <Transition name="fade" mode="out-in">
        <PopUpScreen
          v-if="editOrderDate"
          :isVisible="isPopUpVisible"
          @close="handleCancel"
          :textBox="true"
          :title="overlayTitle"
          @confirm="handleConfirm"
          :btnMessage="btnText"
          :header="headerText"
          @submit="sendApprovalRequest"
          :btnOption="true"
        />
      </Transition>
      <Transition name="fade" mode="out-in">
        <PopUpScreen
          v-if="approvalContent"
          :isVisible="isPopUpVisible"
          @close="handleCancel"
          :textBox="false"
          :title="approvalTitle"
          @confirm="handleConfirm"
          :header="approvalHeader"
          :message="approvalMessage"
          :btnOption="false"
        >
          <div class="slot_btn">
            <button
              :style="{
                backgroundColor: 'white',
                color: '#008060',
                border: '1px solid #008060',
                borderRadius: '4px',
                padding: '12px 0',
                width: '50%',
                marginRight: '12px',
              }"
            >
              Decline
            </button>
            <button
              :style="{ color: 'white', padding: '12px 0', width: '50%' }"
            >
              Approve
            </button>
          </div>
        </PopUpScreen>
      </Transition>
      <Transition name="fade" mode="out-in">
        <DeleteConfirmationPopUp
          :visiblePopUp="showOverlay"
          :index="deleteIndex"
          :icon="dustBinIcon"
          @confirm="handleConfirm"
          @cancel="handleCancel"
          :title="overlayTitle"
          :message="overlayMessage"
          :sub_message="overlaySubMessage"
          @delete="removeOrder()"
        />
      </Transition>
      <Transition :duration="{ enter: 500, leave: 800 }" name="nested">
        <UpdateScreen
          :isVisibleModal="visibleModal"
          @cancel="handleCancel"
          @changePage="addPaymentPop"
          :noPayment="no_payment"
          :paymentStatus="payment_status"
        />
      </Transition>
      <Transition name="fade" mode="out-in">
        <AddPayment
          :isAddPayment="updatePaymentPopUp"
          @cancel="handlePopUpCancel"
        />
      </Transition>
      <Transition :duration="{ enter: 500, leave: 300 }" name="nested">
        <ShareInvoice
          :shareInvoice="shareInvoiceModal"
          @cancel="handlePopUpCancel"
        />
      </Transition>

 




      <Transition :duration="{ enter: 500, leave: 800 }" name="nested">
        <OrderIssue
          :isOrderIssue="issueModal"
          @cancel="handleCancel"
          @showForm="showIssueForm"
          @showChat="showChatBox"
        />
      </Transition>
      <!-- <Transition :duration="{ enter: 500, leave: 800 }" name="nested">
        <AddIssue :isAddIssue="issueForm" @cancel="handlePopUpCancel" />
      </Transition> -->
      <!-- <Transition :duration="{ enter: 500, leave: 800 }" name="fade">
        <IssueChat
          :isChatBox="chatModal"
          @cancel="handlePopUpCancel"
          @statusPopUp="showSetStatusPopUp"
        />
      </Transition> -->
      <Transition :duration="{ enter: 500, leave: 800 }" name="fade">
        <SetStatus
          :isSetStatus="setStatusPopUp"
          @cancel="handleStatusCancel"
        />
      </Transition>
      <Transition :duration="{ enter: 500, leave: 800 }" name="fade">
        <ReturnOrder
          :isReturnOrder="returnOrderModal"
          @cancel="handlePopUpCancel"
          @next="nextPage"
          @subPage="subPage"
          @mainPage="mainPage"
        />
      </Transition>
      <Transition :duration="{ enter: 500, leave: 800 }" name="fade">
        <ConfirmReturn
          @cancel="handlePopUpCancel"
          @next="approval"
          :isConfirmReturn="confirmReturnModal"
          @return="previousPage"
        />
      </Transition>
        <Transition :duration="{ enter: 500, leave: 800 }" name="fade">
        <ReturnApproval
          @cancel="handlePopUpCancel"
          :isReturnOption = "returnApprovalModal"
          @return="goBack"
          

        />
      </Transition>
      <Transition :duration="{ enter: 500, leave: 800 }" name="fade">
        <ReturnedItems
          @cancel="handlePopUpCancel"
          :isReturnItems = "returnItemsModal"
          @return="goBack"
          @subPage="subPage"
          @mainPage="mainPage"

        />
      </Transition>
    </div>
</template>
<style scoped>
button {
  background: #008060;
  border: none;
  border-radius: 4px;
}
button > a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}
button > a > img {
  margin: 0 4px;
}
.slot_btn {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
}
.card_buttom {
  z-index: 200;
  margin-top: 8px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tags {
  display: flex;
  width: 80%;
}
.options {
  position: absolute;
  border-radius: 8px;
  right: 0;
  bottom: -20px;
  padding: 12px;
  background: white;
  z-index: 100;
  display: flex;
  margin: 0 16px;
  flex-direction: column;
  box-shadow: -2px 4px 16px 0px #d1e4de80;
}
.list {
  padding: 8px;
  text-decoration: none;
  border-bottom: #c4dbd5 0.3px solid;
  color: #355f53;
}
.options .list > img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.showItems {
  border-bottom: 1px solid #c4dbd5;
  padding: 8px 0;
}
.cancel {
  color: red;
}
.profile {
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
}
.ct_right {
  display: flex;
  flex-direction: column;
  color: #143e32;
  align-items: flex-end;
}
.ct_right > p {
  font-weight: 500;
}
li {
  font-size: 14px;
  color: #002e22;
  list-style: none;
  padding: 8px;
  font-weight: 400;
}
li:focus,
li:hover {
  color: #008060;
  border-bottom: #008060 1px solid;
}
small,
.payment_mode {
  color: #92aaa3;
}
span {
  /* background: white; */
  padding: 4px 8px;
  border: #c4dbd5 1px solid;
  margin-right: 5px;
  border-radius: 16px;
}
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
.nested-enter-active .inner {
  transition-delay: 0.25s;
}
.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
