<script>
import CustomHeader from "@/components/CustomHeader.vue";
import {
  createProduct,
  updateProduct,
  uploadImages,
  updateImageProduct
} from '@/services/apiServices'

import { mapGetters } from "vuex"
import * as mutationTypes from "@/store/mutationTypes"


export default {
  components: {
    CustomHeader,
  },
  data() {
    return {
      combinations: [],
      combinationsData: [],
      errorMessage: false,
      files: [],
      image_ids: [],
      previewImages: [],
      product: {
        first_variant: "", // Convert array to string to store in db
        first_variant_name: "",
        second_variant: "", // Convert array to string to store in db
        second_variant_name: "",
        variant_options: "", // Holds all variants as string for db storage
        discount: "",
        discount_type: "",
        sku: null,
        category: null,
        combinations: '',
        description: '',
        display: true, //null // display product on shop floor
        has_discount: false,
        has_image: false,
        has_variant: false,
        id: null,
        options1: '',
        options2: '',
        options3: '',
        owner: null,
        price: 0, // base price
        product_image: null,
        product_name: '',
        strict_stock_count: true,
        total_stock: 0,
        variants: '',
      },
      unchangedProduct: {},
      updates: {},
      select_image: true,
      variants: [],
    };
  },
  methods: {
    // Add a new variant input section (limit to 3 variants)
    addVariant() {
      if (this.variants.length < 3) {
        this.variants.push({ name: '', options: [], optionsInput: '' });
      } else {
        alert("You can only add up to 3 variants.");
      }
    },
    backToGallery() {
      // this.saveProduct()
      // saveProduct commits new product to state, and directs back to add_to_cart
    },
    computeVariants() {
      this.product.price === this.unchangedProduct.price
       ? '' 
       : this.product.price = this.product.price * 100 // model needs extra zeros
      this.product.variants = ''
      this.product.options1 = ''
      this.product.options2 = ''
      // this.product.options3 = ''

      for (let i=0; i < this.variants.length; i++) {
        this.product.variants += this.variants[i].name + ','

        i == 0 
          ? this.product.options1 = this.variants[i].optionsInput : ''
        i == 1 
          ? this.product.options2 = this.variants[i].optionsInput : ''
        i == 2 
          ? this.product.options3 = this.variants[i].optionsInput : ''
      }

      this.product.combinations = ''
      for (let i=0; i < this.combinationsData.length; i++) {
        this.product.combinations += this.combinationsData[i].qty + ','
        this.product.combinations += this.combinationsData[i].price + ','
        this.product.combinations += this.combinationsData[i].sku + ';'
      }
    },
    displayImages(event) {
      const selectedFiles = event.target.files

      this.previewImages = []
      this.errorMessage = false

      if (selectedFiles.length > 5) {
        this.errorMessage = true
        this.$refs.imageInput.value = ''
        return
      }

      Array.from(selectedFiles).forEach((file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.previewImages.push(e.target.result)
        }
        reader.readAsDataURL(file)
      });
      
      this.files = Array.from(selectedFiles);      
    },
    // Remove a variant
    removeVariant(index) {
      this.variants.splice(index, 1);
      this.variants.length == 0 ? this.toggleVariants() : null
    },
    
    // Update the options array when the user inputs options
    updateOptions(index) {
      const input = this.variants[index].optionsInput;
      this.variants[index].options = input.split(',').map(option => option.trim());
    },

    // Generate all possible combinations of variants
    generateCombinations() {
      if (this.variants.some(variant => !variant.name || variant.options.length === 0)) {
        alert('Please complete all variant fields with valid options.');
        return;
      }

      // Reset combinations
      this.combinations = [];
      this.combinationsData = [];

      // Generate all combinations from variants
      const variantCombinations = this.cartesianProduct(
        ...this.variants.map(variant => variant.options)
      );

      // Create readable combinations and initialize price/quantity fields
      if (this.variants.length === 1 ) {
        for (let i in this.variants[0].options) {
          this.combinations.push(this.variants[0].options[i])
        }
      } else {
        this.combinations = variantCombinations.map(combination => {
          return combination.join(',');
        });
      }

      this.combinationsData = this.combinations.map(() => ({
        qty: null,
        price: null,
        sku: null
      }));
    },

    // Cartesian product utility function to generate combinations
    cartesianProduct(...arrays) {
      return arrays.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));
    },
    async submitProduct() {
      try {
        this.computeVariants()

        // Submit the product
        const res = await createProduct(this.product)
        let new_product = res.data

        // Update images if any
        if (this.image_ids.length > 0) {
          const imageRes = await updateImageProduct({ image_ids: this.image_ids, prod_id: res.data.id })
          new_product.images = imageRes.data.images
        } else {
          new_product.images = []
        }

        alert(this.product.product_name + " added")

        // Handle variants if the product has them
        if (this.product.has_variant) {
          const convertToArray = (str) => {
            return str ? str.split(",").filter((item) => item !== "") : [];
          }

          let combinationsData = new_product.combinations.split(";").filter((item) => item !== "")

          new_product.variants = convertToArray(new_product.variants)
          new_product.options1 = convertToArray(new_product.options1)
          new_product.options2 = convertToArray(new_product.options2)
          new_product.combinationsData = combinationsData.map((item) => convertToArray(item))
        }

        // Save product in store
        new_product.price = new_product.price/100
        this.$store.commit(mutationTypes.SAVE_PRODUCT, new_product)

        // Navigate based on origin
        if (this.origin === "order") {
          this.$store.commit(mutationTypes.ADD_TO_CART_MAP, this.product.id)
          this.$router.replace({ path: "/add_to_cart" })
        } else {
          this.$router.replace({ path: "/inventory" })
        }
      } catch (err) {
        console.log(err);
      }
    },
    toggleVariants() {
      this.product.has_variant == false ? this.addVariant() : null // should come first
      this.product.has_variant = !this.product.has_variant
    },
    saveEdit() {
      this.computeVariants()

      const updatedProduct = {}
      Object.keys(this.product).forEach(key => {
        if (this.product[key] !== this.unchangedProduct[key]) {
          updatedProduct[key] = this.product[key]
        }
      })

      updateProduct(updatedProduct, this.edit.id)
        .then(() => {
          this.product.price === this.unchangedProduct.price
            ? ''
            : this.product.price = this.product.price / 100 // change back in case upload fails, and for viewing.

          alert(this.edit.product_name + " updated")
        })
    },
    uploadImg() {
      const fileInput = document.getElementById("fileInput");
      const selectedFiles = fileInput.files;

      if (selectedFiles.length === 0) {
        alert("Please add at least one image.");
        return;
      }

      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("images[]", selectedFiles[i]);
      }

      uploadImages(formData)
        .then(res => {
          this.image_ids = []
          for (let i=0; i < res.data.length; i++) {
            this.image_ids.push(res.data[i].id)
          }
          this.view_form()
        })

    },
    view_form() {
      this.select_image = false
    }
  },
  computed: {
    ...mapGetters({
      edit: "getProductToEdit",
      owners: "getOwners",
    }),
    baseUrl() {
      return window.location.origin;
    },
    origin() {
      return this.$route.params.origin
    }
  },
  mounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false)

    if (this.edit.id) {
      this.select_image = false

      // Do this first //
      this.product = this.edit

      // Then unpack variants //
      if (this.product.has_variant) {
        this.variants = []
        for (let i=0; i<this.product.variants.length; i++) {
          this.variants.push({name: this.product.variants[i], options: [], optionsInput: ''})
        }

        if (this.product.variants.length > 0) {
          this.variants[0].optionsInput = this.product.options1
          this.variants[0].options = this.product.options1
        }
        if (this.product.variants.length > 1) {
          this.variants[1].optionsInput = this.product.options2
          this.variants[1].options = this.product.options2
        }
        if (this.product.variants.length > 2) {
          this.variants[2].optionsInput = this.product.options3
          this.variants[2].options = this.product.options3
        }

        this.generateCombinations()

        let combsData = this.product.combinations.split(';').filter(item => item !=="")

        combsData = combsData.map(item => 
            item.split(',').filter(subItem => subItem.trim() !== "")
        )

        let arr = []
        for (let i=0; i < combsData.length; i++) {
          arr.push({qty: combsData[i][0], price: combsData[i][1], sku: combsData[i][2]})
        }
        this.combinationsData = arr
      }

      // Then this //
      this.unchangedProduct = { ...this.edit}
    }
  },
  unmounted() {
    try {
      this.product.variants = this.product.variants.split(',').filter(item => item !=="")
    } catch { null }
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true)
  },
};
</script>

<template>
  <CustomHeader text="Products" />
  <div class="container">
    <div v-if="select_image" style="background: #FFF; padding: 10px; border-radius: 8px;">
      <div style="display: flex; justify-content: space-between;">
        <span style="font-size: 14px; background: #FAEBED; color: #A75A00; padding: 5px 10px; border-radius: 20px;">Note</span>
        <span style="display: none; background: #92AAA3; padding: 5px 10px; border-radius: 15px;">x</span>
      </div>
      <p style="padding: 5px 0">Select up to five (5) photos for your product, or skip.</p>
    </div>
    <div v-if="select_image"> <!-- add image(s) -->
      <form enctype="multipart/form-data">
        <label for="fileInput" class="custom-file-upload">Click to add product photos.</label>
        <input @change="displayImages" ref="imageInput"  style="display: none;" type="file" id="fileInput" name="files[]" multiple accept="image/*"/>

        <div v-if="errorMessage" id="error-message" class="error-message">
        You can only upload a maximum of 5 images.</div>
      </form>

      <div v-if="previewImages.length" id="image-preview-container" style="display: grid; grid-template-columns: auto auto auto; column-gap: 3px; row-gap: 3px;">
        <div v-for="(image, index) in previewImages" 
        :key="index" >
          <div class="image-preview">
            <img 
              :src="image" 
              alt="Image preview"
            />
          </div>
        </div>
      </div>

      <div class="next">
        <button type="button" @click="uploadImg()">Next</button>
        <p class="center" style="text-align: center; margin-top: 20px; cursor: pointer;" @click="view_form()">Skip</p>
      </div>
    </div>

    <div v-else> <!-- add details -->
      <form>
        <!-- Basic Product Information -->
        <div class="form-group">
          <label for="product-name">Name</label>
          <input v-model="product.product_name" type="text" id="product-name" required>
        </div>
        <div class="form-group">
          <label for="base-price">Price</label>
          <input v-model.number="product.price" type="number" pattern="[0-9]*" inputmode="numeric" id="base-price" onwheel="this.blur()" required>
        </div>
        <div class="form-group">
          <label for="base-quantity">Quantity</label>
          <input v-model.number="product.total_stock" type="number" pattern="[0-9]*" inputmode="numeric" id="base-quantity" onwheel="this.blur()" required>
        </div>
        <div class="form-group">
          <label for="product-description">Description (optional)</label>
          <textarea v-model="product.description" id="product-description"></textarea>
        </div>
        <div class="form-group">
          <label for="product-name">SKU (optional)</label>
          <input v-model="product.sku" type="text" id="product-name">
        </div>
        <div class="form-group" v-if="product.owner">
          <label for="base-quantity">Owner (optional)</label>
          <select name="owner" id="" v-model="product.owner">
            <option v-for="(owner, i) in this.owners" :value="owner[i].id" :key="i">
              {{ owner[i].name }}
            </option>
          </select>
        </div>

        <p @click="toggleVariants()" style="margin: 20px 0 15px">
          <span v-if="!product.has_variant"><span>+</span> Add variants like size or colour</span>
          <span v-else><span>-</span> Remove (destroy) all variants</span>
        </p>

        <div v-if="product.has_variant">
          <!-- Loop through dynamic variants -->
          <div v-for="(variant, index) in variants" :key="index" class="variant-group">
            <label :for="'variant-name-' + index">Variant Name</label>
            <input v-model="variant.name" type="text" :id="'variant-name-' + index" placeholder="e.g. Size, Color" required>

            <label :for="'variant-options-' + index">Variant Options (separate each variant with a comma)</label>
            <input v-model="variant.optionsInput" type="text" :id="'variant-options-' + index" placeholder="e.g. Small, Medium, Large" @blur="updateOptions(index)" required>

            <button type="button" @click="removeVariant(index)">Remove Variant</button>
          </div>

          <!-- Add Variants Button (Disabled when 3 variants added) -->
          <div class="form-group" v-if="this.variants.length < 3">
            <button type="button" @click="addVariant" :disabled="variants.length >= 3">
              Add another variant
            </button>
            <span v-if="variants.length >= 3" style="color: red;">Maximum of 3 variants reached</span>
          </div>

          <button type="button" @click="generateCombinations" :disabled="variants.length === 0">
            Enter price, quantity, and SKU of options
          </button>

          <div v-if="combinations.length > 0">
            <h3>Variant Combinations</h3>
            <div v-for="(combination, index) in combinations" :key="index" class="variant-combination">
              <h4>{{ combination }}</h4>
              <label :for="'price-' + index">Price</label>
              <input v-model.number="combinationsData[index].price" type="number" :id="'price-' + index" placeholder="Price for this combination" pattern="[0-9]*" inputmode="numeric" onwheel="this.blur()" required>

              <label :for="'qty-' + index">Quantity</label>
              <input v-model.number="combinationsData[index].qty" type="number" :id="'qty-' + index" placeholder="Quantity for this combination" pattern="[0-9]*" inputmode="numeric" onwheel="this.blur()" required>

              <label :for="'sku-' + index">SKU</label>
              <input v-model.number="combinationsData[index].sku" :id="'sku-' + index" placeholder="SKU for this combination">
            </div>
          </div>
        </div>

        <div class="next">
          <button v-if="!this.edit.id" @click.prevent="submitProduct">Add Product
          </button>
          <button v-else-if="this.origin=='order'" @click.prevent="backToGallery(this.newProduct.id)">Add Product</button>
          <button v-else @click.prevent="saveEdit(edit.id)">Save edit</button>
        </div>
      </form>
    </div>
  </div>
  <div style="height: 50px;"></div>
</template>

<style>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  margin-top: 40px;
  /* margin-bottom: 32px; */
}
.custom-file-upload {
  display: block;
  width: 100%;
  /* background: pink; */
  text-align: center;
  margin: 20px auto;
  border-radius: 8px;
  cursor: pointer;
}
.image-preview {
  aspect-ratio: 1/1;
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  overflow: hidden;
}
.image-preview img {
  width: 100%
}
.next button {
  width: 100%;
  background: #008060;
  color: #FFF;
  border-radius: 8px;
  border: none;
  margin-top: 40px;
  padding: 10px;
}
label {
  display: block;
  margin-bottom: 5px;
  margin-top: 15px;
}
input, select, textarea {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgb(196, 219, 213);
  background: rgb(245, 252, 244)
}
/* prevents scroll in number inputs */
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.variant-combination {
  margin-bottom: 10px;
}
.variant-combination h4 {
  margin: 0;
  font-size: 1.1em;
  margin-bottom: 5px;
}
/* .form-group {
  margin-bottom: 15px;
}


.variant-group {
  margin-bottom: 20px;
}
*/
</style>
