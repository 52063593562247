<script>
import CustomHeader from "@/components/CustomHeader.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Carousel from "@/components/Carousel.vue";
// import OptionsPriceAndQty from "@/components/Inventory/OptionsPriceAndQty.vue";
import penIcon from "@/assets/Images/editPen.svg";
import hamMenu from "@/assets/Images/hamMenu.svg";
import imgAvatar from "@/assets/Images/imgAvatar.svg";
import copyIcon from "@/assets/Images/inventoryDetailsIcon.svg";
import calendar from "@/assets/Images/calendar.svg";
import priceIcon from "@/assets/Images/price.svg";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      copyIcon,
      priceIcon,
      calendar,
      // edit: false,
      hamMenu,
      imgAvatar,
      penIcon,
      variant_options: [],
      variant2Length: 0,

      combinations: [],
      combinationsData: [],
      variants: [],
    };
  },
  components: {
    ButtonComponent,
    Carousel,
    CustomHeader,
    // OptionsPriceAndQty,
  },
  methods: {
    editProduct() {
      // this.edit = true
      this.$router.push("/edit_product")
    },
    getCombinations(variantOptions) {
      let combinations = [[]];

      variantOptions.forEach(options => {
          if (!Array.isArray(options)) {
              throw new Error('Each variant option must be an array');
          }

          let newCombinations = [];
          combinations.forEach(combination => {
              options.forEach(option => {
                  newCombinations.push([...combination, option]);
              });
          });

          combinations = newCombinations;
      });

      return combinations;
    }
  },
  computed: {
    ...mapGetters({
      product: "getProductToEdit",
    }),
    baseUrl() {
      return window.location.origin
    },
    stats() {
      return [
        {
          stat: "Items in stock",
          value: this.product.total_stock,
        }, {
          stat: "Last 30 days",
          value: "7 sales" // also, monetary value and margin
        }, {
          stat: "in last 30 days",
          value: "12th best seller"
        }, {
          stat: "Last purchase",
          value: "Nov 22"
        }
      ]
    }
  },
  mounted() {
    if (this.product.has_variant) {
      if (this.product.variants.length > 0) {
        this.combinations = this.getCombinations([this.product.options1])
      }
      if (this.product.variants.length > 1) {
        this.combinations = this.getCombinations([this.product.options1, this.product.options2])
      }
      if (this.product.variants.length > 2) {
        this.combinations = this.getCombinations([this.product.options1, this.product.options2, this.product.options3])
      }

      this.combinationsData = this.product.combinationsData
    }
  },
};
</script>

<template>
  <CustomHeader text="Product Details" />
<div class="container_main">
 <div class="main">
 <!-- Summary block was here -->
  <div class="holder">

    <div class="holder_first_div">
      <h2>Product image</h2>
      <img :src="hamMenu" alt="ham">
    </div>
    <div v-if="product.images.length > 0" class="pd_centered">
      <Carousel :slides="product.images" />
      <h1>{{ product.product_name }}</h1>
    </div>
    <div v-else class="pd_centered">
      <img src="@/assets/Images/default_product_image.png" alt="img" />
      <h1>{{ product.product_name }}</h1>
    </div>  
    
    <div style="border-bottom: 1px solid #c4dbd5; padding: 10px 0">
      <div class="product_details">
        <h2 style="font-weight: bold;">Product Details</h2>
        <button @click.prevent="editProduct()" class="white">Edit</button>
      </div>
      <div class="list">
        <div>
          <img :src="priceIcon" alt="price icon" />
          <h3>Stock</h3>
        </div>
        <p>{{ product.total_stock }}</p>
      </div>
      <div class="list">
        <div>
          <img :src="priceIcon" alt="price icon" />
          <h3>Price</h3>
        </div>
        <p>&#8358;{{ product.price.toLocaleString() }}</p>
      </div>
      <div class="list">
        <div>
          <img :src="calendar" alt="calendar icon" />
          <h3>SKU</h3>
        </div>
        <p>{{ product.sku }}</p>
      </div>
      <div class="list">
        <div>
          <img :src="calendar" alt="calendar icon" />
          <h3>Date added</h3>
        </div>
        <p>{{ product.date }}</p>
      </div>

      <div class="list">
        <div>
          <img :src="copyIcon" alt="copy icon" />
          <h3>Description</h3>
        </div>
      </div>
      <p class="desc">{{ product.description }}</p>
    </div>
  </div>

 </div>
 <!-- variant options preview -->
  <div v-if="product.has_variant" class="table">
    <div class="variant_title">
      <h2 style="font-weight: bold;">Product Variants</h2>
    </div>
    <!-- <div class="table_header">
      <h3>Options </h3>
      <div class="smaller_section">
        <h3>Qty</h3>
        <h3>Price</h3>
      </div>
    </div> -->
    <div v-for="combo, i in combinations" :key="i" class="variants">
      <div>
        <!--weirdly, combo[i]... is not getting the results-->
        <!--thus using combinations[i][0] as a hack-->
        <!-- <h3>{{ combo[i] }} / {{ combo[i+1] }}</h3> -->
        <h3>
          {{ combinations[i][0] }} <span v-if="variants.length > 1">/</span>
          {{ combinations[i][1] }} <span v-if="variants.length > 2">/</span> {{ combinations[i][2] }}
        </h3>
        <p><span>Price: </span> &#8358;{{ Number(combinationsData[i][1]).toLocaleString() }}</p>
      </div>
      <div>
        <p><span>Qty: </span> {{ combinationsData[i][0] }}</p>
        <p><span>SKU: </span>{{ combinationsData[i][2] }}</p>
      </div>
    </div>
  </div>
  <ButtonComponent v-if="feedback">View Product Feedback</ButtonComponent>
  <div style="display: block; height: 50px"><!--props page up--></div>
</div>
</template>

<style scoped>

.container_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 16px;
}
.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.list img, .list h3 {
  display: inline-block;
  font-weight: lighter;
}
.list img {
  margin-right: 7px;
}
.variants {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
  margin-bottom: 10px;
}



.main, .table{
  width: 100%;
}
.table h3{
  font-size: 16px;
  font-weight: 400;
}
.table_header{
  padding: 8px;
 display: flex;
    justify-content: space-between;
    background-color: #F5FCF4;
    border-bottom: #C4DBD5 1px solid;
}
.variant_title{
  padding: 12px 0;
  display: flex;
  justify-content: space-between
}
.smaller_section{
    display: flex;
    justify-content: space-around;
    width: 45%;
}
.header_link {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 0 16px;
  margin-bottom: 8px;
}

.header_link a {
  color: #008060;
}
h1 {
  color: #002e22;
  font-weight: 500;
  font-size: 24px;
  margin: 4px 0;
}
h2 {
  color: #002e22;
  font-size: 16px;
  font-weight: 500;
}
.product_details {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
.product_details button {
  color: whte;
  border-radius: 8px;
  padding: 8px;
  border: #c4dbd5 1px solid;
  margin-top: -5px;
}
.pd_centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #c4dbd5;
}

.pd_centered img{
  max-width: 300px;
  border-radius: 8px;
}
.product_image{
  width: 88px;
  height: 88px;
  object-fit: contain;
}

.holder_first_div{
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;
}
.desc {
  color: #355f53;
  font-size: 16px;
  padding: 8px;
}
.card_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: #c4dbd5 1px solid;
  padding-bottom: 8px;
}

.card_top_details {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.card_top_details > img {
  margin-right: 12px;
}

.ct_info > h1 {
  font-size: 14px;
  color: #355f53;
  margin-bottom: 4px;
  font-weight: 400;
  margin-bottom: 4px;
}

.ct_info {
  display: flex;
  flex-direction: column;
}

.ct_info > div {
  display: flex;
}

.ct_info > div > p {
  font-weight: 500;
  font-size: 14px;
  margin-right: 4px;
  color: #002e22;
  margin-top: 4px;
}

.card_buttom {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.card_buttom > p {
  margin: 0 12px;
  width: 40px;
  height: 40px;
  color: #355f53;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #c4dbd5 1px solid;
}
.white{
  color: #008060;
  font-weight: bold;
  background-color: white;
  padding: 6px 8px;
  border: 1px #c4dbd5 solid;
  border-radius: 4px;
}

</style>
