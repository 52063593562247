export default {
  account_id: null,
  campaign_templates: [
    { name: "default_template.html", id: 1, url: "" },
    { name: "default_template2.html", id: 2, url: "" },
    { name: "eid.html", id: 3, url: "" },
    { name: "eid_sample.html", id: 4, url: "" },
    { name: "fathers_day_4.html", id: 4, url: "" },
  ],
  cart_map: [],
  cart_unpacked: [],
  cart_checkout: [],
  currency: {
    CAD: 'CA'+'&#36;',
    EUR: '&#8364;',
    GBP: '&#163;',
    GHS: '&#8373;',
    NGN: '&#8358;',
    USD: '&#36;'
  },
  customers: [],
  customer_stats: {},
  customer_to_edit: {},
  checkout_customer: {},
  component_settings: {
    header_settings: null,
    display_next_button: null,
    page_title: "",
  },
  display_app_nav: true,
  email_verified: false,
  filter_option: "Customer name",
  has_product: false,
  has_customer: false,
  has_sale: false,
  inventory: [],
  logged_in: false,
  metrics: {},
  nav_position: 0,
  sales_report: {},
  settings: false,
  settlement: {},
  store: {},
  store_slug: "",
  orders: [],
  order: null,
  owners: [],
  to_be_editted: {},
  unregistered_email: "",
  unsaved_change: false,
  visitor_inventory: {},
}