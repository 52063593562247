<script>
import cancelDark from "@/assets/Images/cancelDark.svg";

import MarkComplete from "@/components/Order/MarkComplete.vue";

export default {
  name: 'MenuOptionsLarge',
  components: {
    MarkComplete,
  },
  props: {
    option: {
      type: String,
      required: true,
      default: null,
    },
    order: [],
    // properties: [],
  },
  data() {
    return {
      cancelDark,
      title: '',
      // displayLargeModal: true,
    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel")
      this.$emit("closeMenu")
    },
  },
  watch: {
    option() {
      switch (this.option) {
        case 'markComplete':
          this.title = "Mark fulfilled"
          // this.displayLargeModal = false
          break;
        case 2: // update paid amount
          null
          break;
        case 3: // cancel
          null
          break;
        case 4:
          null
          break;
        default:
          null
      }
    }
  }
};
</script>
<template>
  <div class="modal" v-if="option != null">


    <div class="main">
      <div class="cancel" @click="closePopup">
        <div><!-- flex --></div>
        <div class="img">
          <img :src="cancelDark" alt="cancel" />
        </div>
      </div>
      <div class="menu">
        <!-- Title -->
        <div class="title">
          <h1 class="h1">{{ title}}</h1>
        </div>

        <!-- Unique content -->
        <div>
        <MarkComplete
          v-if="option=='markComplete'"
          :order=order
        />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  overflow: auto;
}
.main {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 75%;
  overflow: scroll;
}
.menu {
  background: #F0F5EF;
  border-radius: 8px 8px 0 0;
  min-height: 90%;
}
.cancel {
  display: flex;
  justify-content: space-between;
}
.cancel .img {
  display: inline-block;
  background: #F0F5EF;
  padding: 15px 15px 10px 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.cancel img {
  width: 16px;
}
.h1 {
  font-size: 22px;
  padding: 10px 10px;
  border-bottom: 1px solid;
}
</style>
