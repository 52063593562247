<script>
import returnHome from "@/assets/Images/returnHome.svg";
import hero from "@/assets/Images/hero.svg";
import Header from "@/components/LandingPage/LandingPageHeader.vue";
import { apiLogin } from "@/services/apiServices";
import * as mutationTypes from "@/store/mutationTypes";

import axios from "axios";

export default {
  name: "LoginView",
  components:{
Header
  },
  data() {
    return {
      hero,
      email: "",
      password: "",
      errorMessage: false,
      returnHome
    };
  },
  computed: {
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    isFormValid() {
      return this.isEmailValid && this.password;
    },
    isFilled() {
      return this.email !== "" && this.password !== "";
    },
  },
  methods: {
    handleSubmit(event) {
      if (!this.isFormValid) {
        event.preventDefault();
        this.errorMessage = true;
        return;
      }
      this.errorMessage = false;
      this.Login();
    },
    Login() {
      let data = { username: this.email, password: this.password };
      apiLogin(data)
        .then((res) => {
          // localStorage.setItem("leyyow_token", res.data.token)
          sessionStorage.setItem("leyyow_token", res.data.token)
          axios.defaults.headers.common[
            "Authorization"
          ] = `Token ${res.data.token}`;

          let store = res.data.store;
          store.slug = res.data.slug;

          this.$store.commit(mutationTypes.LOGGED_IN, true);
          this.$store.commit(mutationTypes.SAVE_STORE, store);
          this.$store.commit(mutationTypes.SAVE_STORE_SLUG, res.data.slug);
          // this.$store.commit(mutationTypes.SAVE_SETTLEMENT, res.data.settlement)
          this.$store.commit(mutationTypes.SAVE_ACCOUNT_ID, res.data.account_id);
          this.$store.commit(mutationTypes.SAVE_STORE_CUSTOMERS, res.data.customers);
          this.$store.commit(mutationTypes.SAVE_OWNERS, res.data.owners);

          let inventory = res.data.inventory.map(product => {
          const convertToArray = (str) => {
              return str ? str.split(',').filter(item => item !== "") : []
            };

            let combinationsData = product.combinations.split(';').filter(item => item !=="")

            return {
              ...product,
              variants: convertToArray(product.variants),
              options1: convertToArray(product.options1),
              options2: convertToArray(product.options2),
              combinationsData: combinationsData.map(item => convertToArray(item)),
              images: res.data.images.filter(image => image.product === product.id)
            }
          })
          this.$store.commit(mutationTypes.SAVE_INVENTORY, inventory);
          
          store.verified[0] == 1
            ? this.$store.commit(mutationTypes.EMAIL_VERIFIED, true)
            : this.$store.commit(mutationTypes.EMAIL_VERIFIED, false);
          this.$router.push("/dashboard");
        })
        .catch((err) => {
          console.log(err);
          // alert("Wrong or invalid credentials, or other error. Please try again")
        });
      // .finally(() => {
      // 	this.loading = false
      // });
    },
  },
  mounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);
  },
  unmounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true);
  },
};
</script>
<template>
  <div class="hello">
    <Header r :navItems="navItems" />

    <img :src="hero" alt="hero" />
    <div class="details">
      <h1>Your sales tracking app</h1>
      <p>
        Track your sales from ordering to delivery, and even after-sales issues.
      </p>
      <h2>Welcome back 🙌</h2>
      <form @submit.prevent="handleSubmit">
        <label for="">Email</label>
        <input
          type="email"
          v-model="email"
          placeholder="example@email.com"
          :class="{ error: errorMessage && !isEmailValid }"
        />
        <div v-if="errorMessage">
          <span v-if="!isEmailValid">Invalid email format.</span><br />
        </div>


        <label for="">Password</label
        ><input
          v-model="password"
          type="password"
          :class="{ error: errorMessage && !password.length <= 4 }"
        />
        <div v-if="errorMessage">
          <span v-if="!password.length < 4">Incorrect password</span>
        </div>

        <RouterLink class="fp" to="/forgotPassword/">
          Forgot Password?</RouterLink
        >

        <button
          type="submit"
          :style="{ backgroundColor: isFilled ? '#008060' : '#C4DBD5' }"
        >
          Login
        </button>
      </form>
      <footer>
        <p>
          Don't have an account?
          <RouterLink to="/signup">Create one</RouterLink>
        </p>
        <p class="privacy">
          By signing up, I agree to the
          <a href="#">Leyyow Privacy Policy and Terms of Services</a>
        </p>
      </footer>
    </div>
  </div>
</template>


<style scoped>
* {
  box-sizing: border-box;
}
img {
  width: 100%;
}
h1 {
  font-weight: 500;
  font-size: 32px;
  color: #002e22;
}
p {
  font-weight: 400;
  color: #355f53;
  font-size: 16px;
  margin-bottom: 20px;
}

h2 {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 400;
  color:#002e22;
}
.arrowReturn{
  padding: 8px 0px;
}

.arr {
  padding: 4px;
  background-color: rgba(53, 95, 83, 0.5);
  border-radius: 4px;
  width:24px;
  height:24px;
}
.hello {
  padding: 8px;
  width: 100%;
}
.details {
  padding: 8px;
}
form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
label {
  margin-bottom: 3px;
  font-weight: 400;
  line-height: 20.3px;
  color: #355f53;
  font-size: 14px;
}
input {
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  outline: none;
  cursor: pointer;
}
input::placeholder {
  color: #92aaa3;
}
input:focus {
  border: #002e22 1px solid;
}
span {
  font-size: small;
  color: red;
  margin-bottom: 8px;
}
.error {
  border: 1px red solid;
}
button {
  width: 100%;
  margin: 16px 0;
  color: white;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0px 2px 5px 0px rgba(168, 215, 196, 0.75);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.fpass {
  margin: 8px 16px;
  width: 100%;
  text-align: right;
  color: #355f53;
}
.fp {
  text-align: right;
  width: 100%;
  text-decoration: none;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #008060;
}
footer {
  text-align: center;
}
.privacy {
  margin-top: 148px;
}
.privacy > a {
  color: #355f53;
}
</style>
