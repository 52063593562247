const prefix = process.env.VUE_APP_API_BASE_URL
// const prefix = "http://127.0.0.1:8000"

// Signup and registration
// export const joinWaitListUrl = `${prefix}/account/waitlist/join/`
export const loginUrl = `${prefix}/account/get_auth_token/`

// export const requestPasswordResetUrl = `${prefix}/api/account/password_reset/`
export const requestPasswordResetUrl = `${prefix}/dj-rest-auth/password/reset/`

export const signUpUrl = `${prefix}/account/signup/`
// .post(process.env.VUE_APP_API_BASE_URL + 'api/account/signup/'
// .post(process.env.VUE_APP_API_BASE_URL + 'user/api_signup_login/'

// export const updatePasswordUrl = `${prefix}/account/password_reset/confirm/`
// export const verifyEmailPhoneUrl = `${prefix}/account/verify_email_phone/`
// export const verifyPasswordResetTokenUrl = `${prefix}/account/password_reset/verify_token/`
// export const verifyWaitlistEmailUrl = `${prefix}/account/waitlist/verify_email/`


// Store
// export const createStoreUrl = `${prefix}/api/account/store/create/`
export const createStoreUrl = `${prefix}/user/api_create_store/`
export const updateStoreUrl = `${prefix}/account/store/update/`
export const storeUrl = `${prefix}/account/store/gadgets`

// Paystack
export const bankListUrl = `https://api.paystack.co/bank`
export const createPaystackCustomerUrl = `https://api.paystack.co/customer`
export const createSubAccUrl = `https://api.paystack.co/subaccount`
export const paystackInvoiceRequestUrl = `https://api.paystack.co/paymentrequest`
export const resolveAccUrl = `https://api.paystack.co/bank/resolve?account_number=`
export const transCodeUrl = `https://api.paystack.co/transferrecipient`

// Campaigns
export const campaignDetailUrl = `${prefix}/campaign/detail/`
export const createCampaignUrl = `${prefix}/campaign/create/`
export const listCampaignsUrl = `${prefix}/campaign/list/`
export const updateCampaignUrl = `${prefix}/campaign/update/`

// Customer
export const bulkUploadCustomersUrl = `${prefix}/account/customer/create/bulk/`
export const saveCustomerUrl = `${prefix}/account/customer/create/`
export const updateCustomerUrl = `${prefix}/account/customer/`
export const fetchCustomerStatsUrl = `${prefix}/account/customer/stats/`

// Products
export const bulkUploadProductsUrl = `${prefix}/inventory/product/create/bulk/`
export const createImageUrl = `${prefix}/inventory/product/image/create/`
export const createProductFromTemplateUrl = `${prefix}/inventory/product/create/from_template/`
export const createProductUrl = `${prefix}/inventory/product/create/`
export const uploadImagesUrl = `${prefix}/inventory/product/image_create/`
export const updateImageProductUrl = `${prefix}/inventory/product/image_product_update/`
export const deleteProductUrl = `${prefix}/inventory/product/delete/`
export const initialiseProductUrl = `${prefix}product/initialise/`
export const inventoryUrl = `${prefix}/inventory/product/`
export const productImageUpdateUrl = `${prefix}product/image_update/`
export const productQtyUrl = `${prefix}product/qty/`
export const updateProductUrl = `${prefix}/inventory/product/update/`
export const toggleStrictStockUrl = `${prefix}/inventory/product/update_strict_stock/`

// Orders
export const deleteOrderUrl = `${prefix}/inventory/order/delete/`
export const ordersUrl = `${prefix}/inventory/order/`
export const orderItemsUrl = `${prefix}/inventory/order_items/`
export const updateFulfilledUrl = `${prefix}/inventory/order_item/update_fulfilled/`
export const updateOrderItemUrl = `${prefix}/inventory/order_item/update/`
export const updateOrderUrl = `${prefix}/inventory/order/update/`
export const saveOrderUrl = `${prefix}/inventory/order/create/`
export const saveOrderItemUrl = `${prefix}/inventory/order_item/create/`

// Metrics
export const metricsUrl = `${prefix}/inventory/order/metrics/`
export const eodReportUrl = `${prefix}/inventory/eod_report/`
export const salesReportUrl = `${prefix}/inventory/sales_report/`
export const userRegCountUrl = `${prefix}/account/user/metrics/`

// Merchant payments
export const saveBankUrl = `${prefix}/settlement/bank/create/`


