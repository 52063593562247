<script>
// import Checkbox from "@/components/CheckBox.vue";

import {
  fetchOrderItems,
  updateFulfilled
} from "@/services/apiServices";

export default {
  name: 'MarkComplete',
  components: {
    // Checkbox,
  },
  props: {
    order: [],
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    selectItem(id) {
      updateFulfilled(id)
        .then(res => {
          res.data == '' ? '' : ''
        })
    }
  },
  computed: {
    // Don't delete. For marking each piece of an order item individually // 
    expandedItems() {
      return this.items.flatMap(item => 
        Array(item.qty).fill(item)
      )
    }
  },
  mounted() {
    fetchOrderItems(this.order.order_ref).then(res => {
      this.items = res.data
    })
  }
};
</script>
<template>
  <!-- Unique content for MarkComplete -->
   
  <!-- Would be ideal to show order number and customer name here -->
  <div class="main">
    <div style="width: 15px;"><!-- flex-as-padding --></div>
    <div style="display: flex; flex-direction: column; overflow: scroll; width: 100%">
      <div class="subject">
        <h2>{{ items.length }} item<span v-if="items.length > 1">s</span></h2>
        <p style="color: #008060;">Mark complete</p>
      </div>

      <div class="order_content" v-for="item, i in items" :key="i">
        <div style="display: flex;">
          <!-- <Checkbox @click="selectItem(item.id)" :ref="'ref_' + item.id" /> -->
          <p>{{ i+1 }}</p>
        
          <div class="item_image">
            <img src="@/assets/Images/default_product_image.png" alt="img" />

            <!-- <div class="item_image">
              <div v-if="item.images.length > 0">
                <img
                  v-if="baseUrl == 'http://127.0.0.1:8080'"
                  :src="'http://127.0.0.1:8000' + item.images[0].image"
                  alt="img"
                />
                <img v-else :src="item.images[0].image" alt="img" />
              </div>
              <div v-else>
                <img src="@/assets/Images/default_product_image.png" alt="img" />
              </div>
            </div> -->
          </div>

          <div style="margin-left: 15px;">
            <h2>{{ item.product_name }}</h2>
            <p>&#8358; {{ item.price_sold.toLocaleString() }}</p>
          </div>
        </div>

        <div class="fulfilled">
          <div @click="selectItem(item.id)">
            <span v-if="item.status == 0">&#x2010;</span>
            <span v-if="item.status == 1">&#x2705;</span>
            <span v-if="item.status == 2">&#x2190;</span>
          </div>
          <input type="checkbox" @click="selectItem(item.id)">
          <!-- <p class="unit">
            {{ item.qty }} unit<span v-if="item.qty > 1">s</span>
          </p> -->

        </div>
      </div>
    </div>
    <div style="width: 15px;"><!-- flex-as-padding --></div>
  </div>
  <!-- End, unique content -->
</template>

<style scoped>
.subject {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  align-items: center;
}
h2 {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #143e32;
}
p {
  color: #355f53;
}
.main {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
}
.order_content {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin: 10px 0;
}

.item_image{
  width: 50px;
  border-radius: 6px;
  outline: #c4dbd5 1px solid;
  margin-left: 15px;
}
.item_image img{
  width: 100%;
  object-fit:contain;
}

input {
  width: 20px;
}
.fulfilled {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 60px;
}
.unit {
  height: fit-content;
  padding: 5px 10px;
  border-radius: 24px;
  background: white;
  border: 1px solid #c4cdc5;
  color: #92AAA3;
}
</style>
